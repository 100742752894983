<template>
  <div>
    <AdminHeader @mobile:toggle="TOGGLE_BUTTON"></AdminHeader>
    <div class="row s-admin-body">
      <AdminSidebar
        :class="{ 's-admin-sidebar-mobile': showMobileMenu }"
        @close:button="showMobileMenu = false"
        ref="sidebar"
      ></AdminSidebar>
      <div class="s-admin-content">
        <router-view></router-view>
        <AdminFooter></AdminFooter>
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from "@/components/admin/shared/header";
import AdminSidebar from "@/components/admin/shared/sidebar";
import AdminFooter from "@/components/admin/shared/footer";
export default {
  components: {
    AdminFooter,
    AdminHeader,
    AdminSidebar,
  },
  data() {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    TOGGLE_BUTTON(e) {
      this.showMobileMenu = !this.showMobileMenu;
    },
  },
};
</script>

<style>
</style>