<template>
  <div class="s-admin-sidebar">
    <div class="text-right px-4">
      <span class="d-sm-none" @click="$emit('close:button')"
        ><font-awesome-icon class="font-size-24" :icon="faTimes"
      /></span>
    </div>
    <label class="s-admin-sidebar__menu-title">{{ $t("menu") }}</label>
    <ul class="s-admin-sidebar__list">
      <li v-for="(link, i) in managerLinkList" :key="i">
        <router-link
          :to="link.to"
          exact-active-class="active"
          class="s-admin-sidebar__list__item"
        >
          <i class="s-admin-sidebar__list__item__icon">
            <font-awesome-icon :icon="link.icon" />
          </i>
          <span class="s-admin-sidebar__list__item__label">{{
            $t(link.label)
          }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUsers, faTimes, faAdd, faGifts, faTrash, faList } from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";
export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faTimes,
      faGifts,
      faList,
      links: [
        { icon: faUsers, label: "customers", to: "/admin/kunden", role: "" },
        { icon: faUsers, label: "users", to: "/admin/users", role: "" },
        { icon: faTrash, label: "trashUsers", to: "/admin/trash-users", role: "" },
        { icon: faList, label: "plainText", to: "/admin/logs", role: "" },
        {
          icon: faAdd,
          label: "admins",
          to: "/admin/admins",
          role: "Manager",
        },
        {
          icon: faGifts,
          label: "campaigns",
          to: "/admin/campaigns",
          role: "Manager",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["activeUser"]),
    managerLinkList() {
      if (this.activeUser.role === "Manager") return this.links;
      else if (this.activeUser.role === "Admin")
        return this.links.filter(
          (item) => item.role === "Admin" || item.role === ""
        );
      else [];
    },
  },
};
</script>

<style lang="scss">
.s-admin-sidebar__list {
  li {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
</style>