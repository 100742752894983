<template>
  <div>
    <div class="d-none d-md-block">
      <div class="s-admin-header">
        <div>
          <span @click="$emit('mobile:toggle', true)">
            <font-awesome-icon :icon="faBars" class="font-size-24 mr-2 d-md-none"
          /></span>
          <router-link :to="{ name: 'admin-layout' }">
            <img
              src="@/assets/img/logo.png"
              alt=""
              class="s-admin-header__image"
            />
          </router-link>
        </div>
        <div>
          <b-dropdown
            variant="transparent"
            v-model="$i18n.locale"
            class="lang-dropdown"
          >
            <template #button-content>
              <img
                width="30"
                :src="require(`@/assets/img/icon/${$i18n.locale}.png`)"
                alt=""
              />
            </template>
            <b-dropdown-item
              :value="item"
              v-for="(item, index) in $i18n.availableLocales"
              @click="CHANGE_LANG(item)"
              :key="index"
            >
              <div class="text-center">
                <img
                  width="30"
                  :src="require(`@/assets/img/icon/${item}.png`)"
                  alt=""
                />
              </div>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown size="lg" variant="transparent" no-caret>
            <template #button-content>
              <button class="btn-xs blue-button">
                <font-awesome-icon :icon="faUser" />
              </button>
            </template>
            <b-dropdown-item @click="LOGOUT">{{
              $t("logout")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="d-md-none">
      <div class="s-admin-header">
        <span @click="$emit('mobile:toggle', true)">
          <font-awesome-icon :icon="faBars" class="font-size-24 mr-2"
        /></span>
        <div>
          <router-link :to="{ name: 'admin-layout' }">
            <img
              src="@/assets/img/logo.png"
              alt=""
              class="s-admin-header__image"
            />
          </router-link>
        </div>
        <b-dropdown
          variant="transparent"
          v-model="$i18n.locale"
          class="lang-dropdown"
        >
          <template #button-content>
            <img
              width="30"
              :src="require(`@/assets/img/icon/${$i18n.locale}.png`)"
              alt=""
            />
          </template>
          <b-dropdown-item
            :value="item"
            v-for="(item, index) in $i18n.availableLocales"
            @click="CHANGE_LANG(item)"
            :key="index"
          >
            <div class="text-center">
              <img
                width="30"
                :src="require(`@/assets/img/icon/${item}.png`)"
                alt=""
              />
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <div>
          <b-dropdown size="lg" variant="transparent" no-caret>
            <template #button-content>
              <button class="btn-xs blue-button">
                <font-awesome-icon :icon="faUser" />
              </button>
            </template>
            <b-dropdown-item @click="LOGOUT">{{
              $t("logout")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUser, faBars } from "@fortawesome/free-solid-svg-icons";
export default {
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faUser,
      faBars,
    };
  },
  methods: {
    LOGOUT() {
      this.$store.dispatch("SET_LOGOUT_ACTION");
      this.$router.push("/admin/anmeldung");
    },
    CHANGE_LANG(val) {
      this.$i18n.locale = val;
      localStorage.setItem("lang", val);
    },
  },
};
</script>

<style>
</style>